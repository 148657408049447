import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image';

import Footer from '../components/footer';
import Button from '../components/button';
import PhoneNumber from '../components/phoneNumber';

const ThankyouPage = ( props ) => {

    return (

      <>

        <header className="fixed top-0 left-0 z-50 w-full max-w-full mx-auto bg-teal-500 p-2 flex flex-wrap items-center justify-between sm:px-8 sm:mb-8">

            <Img className="order-last w-32 my-4 mx-auto" fluid={props.data.logoWhite.childImageSharp.fluid} />

        </header>

        <div className="text-center pt-40 px-8 sm:pt-48">
          <h2 className="text-center mb-16">Thank You</h2>
          <p className="md:w-1/3 mx-auto">Thank you for submitting your enquiry, we are excited to hear from you. One of our experienced account managers will be in touch to discuss your project further. If you would prefer to call us feel free to contact us on the number below.</p>
        </div>

        <div className="px-8 mx-auto text-center md:w-1/3">

          <Button url="https://curiousbookco.com/" text="Go home" classes="block mb-64" />

        </div>

        <Footer img={props.data.logo.childImageSharp.fluid} />
    </>

  )
}

export default ThankyouPage

export const pageQuery = graphql`
  query {
    logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    logoWhite: file(relativePath: { eq: "logo-white.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
